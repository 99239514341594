import { createApp } from 'vue'
import App from './App.vue'

//globals
import InlineSvg from 'vue-inline-svg'
import VueGtag from "vue-gtag-next"
import router from './router'
import { store } from './store'

//8 is LA, 7 without daylight savings
function scIsDateReached(yy, mm, dd, h, m, s, hours_timezone_adjust='') {
    let ret = false

    if (yy === null) yy = 1970
    if (mm === null) mm = 1
    if (dd === null) dd = 1
    if (h === null) h = 0
    if (m === null) m = 0
    if (s === null) s = 0
    mm -= 1 //month starts at 0

    var od = new Date(yy, mm, dd, h, m, s)
    var cd = new Date()

    if (hours_timezone_adjust === "undefined" || hours_timezone_adjust === undefined) {
        hours_timezone_adjust = ""
    }

    if (hours_timezone_adjust !== "") {
        var offset = cd.getTimezoneOffset()
        offset = -1 * (offset / 60)  //negative is east of GMT, positive is west of GMT
        offset = offset - hours_timezone_adjust //our adjust uses the regular with negative for west of GMT
        //8 is LA, 7 without daylight savings

        od.addHours(offset)
    }

    //alert(od);
    //console.log("Current Date: " + cd, "Checking for Date: " + od)
    if (cd >= od) {
        ret = true
    } else {
        ret = false
    }
    return ret
}

window.config = {
    previewMode: false,
    phase: 'nowplaying' //pres
}

if (scIsDateReached(2023,2,10,0,0,0)) {
    window.config.phase = 'nowplaying'
} else if (scIsDateReached(2023,2,16,0,0,0)) {
    window.config.phase = 'ondemand'
}
if(location.hostname.match('dev.hueserafilm.com') || location.hostname.match('localhost')){
    window.config.previewMode = true
    //window.config.phase = 'nowplaying'
    //window.config.phase = 'ondemand'
}
window.config.phase = 'ondemand'
//console.log('phase', window.config.phase)



startApp()

function startApp () {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)


    //ANALYTICS - in your main.js, below loading your router
    if (process.env.VUE_APP_GTAG) {
        app.use(VueGtag, {
            property: {
                id: process.env.VUE_APP_GTAG
            }
        })
    }

    //global inline svg
    app.component('inline-svg', InlineSvg)

    app.mount('#app')
}