<template>
    <section id="section-home">
        <div id="main-bg-image" class="bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`"></div>
        <div class="panel-outer">
            <div class="panel">
                <div class="panel2">
                    <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
                    <div class="quotes-holder-outer">
                        <QuotesHolder :quotes="quotes"></QuotesHolder>
                    </div>

                    <button class="ytplayer stagger1" @click="gh.openOverlay('video')">
                        <div class="bg">
                            <img class="bg-frame" src="@/assets/images/home/player.jpg">
                        </div>
                        <div class="icon stagger1">
                            <div class="icon2">
                                <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                            </div>
                        </div>
                    </button>
                    <!--
                    <div class="l3 style2 stagger1">Sign up for updates & exclusive content.</div>
                    <div class="signup stagger1">
                        <EmailSignup
                            mcDomain="https://lullabymovieofficial.us20.list-manage.com"
                            mcUserId="b25b645d846865015940afb4d"
                            mcListId="75ee562ba7"
                        ></EmailSignup>
                    </div>
                    -->
                    <div class="dates stagger1">
                        <div v-if="window.config.phase === 'pre'">
                            <div class="l-small style2">IN THEATERS <span class="nobreak">FEBRUARY 10</span></div>
                            <div class="l-small style2">ON DEMAND EVERYWHERE <span class="nobreak">FEBRUARY 16</span></div>

                            <div class="l-small">
                                <button @click="openAction">
                                    <span>COMING TO SELECT THEATERS</span>
                                </button>
                            </div>

                        </div>
                        <div v-else-if="window.config.phase === 'nowplaying'">
                            <div class="l-small style2">ON DEMAND EVERYWHERE FEBRUARY 16</div>

                            <div class="l-small">
                                <button @click="openAction">
                                    <span>NOW IN SELECT THEATERS</span>
                                </button>
                            </div>
                            <div class="l-small">
                                <a :href="fandangoLink" target="_blank">
                                    <button >
                                        <img src="@/assets/images/fandango-small.png"/> <span>GET TICKETS</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div v-else-if="window.config.phase === 'ondemand'">
                            <div class="l-small style2">ON DEMAND EVERYWHERE</div>
                            <div class="l-small">
                                <button @click="openAction">
                                    <span>WATCH NOW</span>
                                </button>
                            </div>
                            <!--
                            <div class="l-small">
                                <a :href="fandangoLink" target="_blank">
                                    <button >
                                        <img src="@/assets/images/fandango-small.png"/> <span>GET TICKETS</span>
                                    </button>
                                </a>
                            </div>
                            -->
                        </div>




                        <!--
                        <div class="l-big">NOW PLAYING</div>

                        -->
                    </div>
                </div>
            </div>
        </div>
        <div id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>


            <div class="order-inner">
                <!--
                <div class="image-popup-outer">
                    <div v-if="window.config.phase==='pre'" class="image-popup" :style="`background-image: url(` + require('@/assets/images/popup.jpg') + `);`"></div>
                    <a v-else :href="fandangoLink" target="_blank">
                        <div class="image-popup" :style="`background-image: url(` + require('@/assets/images/popup.jpg') + `);`"></div>
                    </a>
                </div>
                -->

                <div class="order-inner2">

                    <div class="bugs">

                        <button class="bug">
                            <a href="https://www.amazon.com/Huesera-Bone-Woman-Natalia-Soli%C3%A1n/dp/B0B8TJL53F/ref=sr_1_1?crid=14ZE4LS7IU1XO&keywords=huesera+the+bone+woman&qid=1676560302&s=instant-video&sprefix=huesera%2Cinstant-video%2C129&sr=1-1"
                               target="_blank">
                                <img src="@/assets/images/order/amazon.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://tv.apple.com/us/movie/huesera-the-bone-woman/umc.cmc.3rge95jz9jjpl986xpgm5krm5"
                               target="_blank">
                                <img src="@/assets/images/order/apple.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.xfinity.com/stream/entity/7564146404258376112"
                               target="_blank">
                                <img src="@/assets/images/order/xfinity.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.directv.com//movies/Huesera-The-Bone-Woman-bSt0aU5vSVcrcXdNOC9IUFFlZi9jUT09"
                               target="_blank">
                                <img src="@/assets/images/order/directv.svg"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://play.google.com/store/movies/details?id=Gm0lNN10VVM.P&pli=1"
                               target="_blank">
                                <img src="@/assets/images/order/google.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href=" https://www.microsoft.com/en-us/p/huesera-the-bone-woman/8d6kgwxzbwd2?activetab=pivot:overviewtab"
                               target="_blank">
                                <img src="@/assets/images/order/microsoft.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://ondemand.spectrum.net/movies/22602940/huesera/"
                               target="_blank">
                                <img src="@/assets/images/order/spectrum.svg"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://tv.verizon.com/watch/movie/Huesera:-The-Bone-Woman/MV019705160000?idType=TMS&qualifiedId=TMS-MV019705160000"
                               target="_blank">
                                <img src="@/assets/images/order/verizon.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.vudu.com/content/movies/details/Huesera-The-Bone-Woman/2249517?cmp=OrganicSearch~Vudu~GoogleWatch" target="_blank">
                                <img src="@/assets/images/order/vudu.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.redbox.com/ondemand-movies/huesera-the-bone-woman" target="_blank">
                                <img src="@/assets/images/order/redbox.png"/>
                            </a>
                        </button>



                    </div>

                </div>

            </div>
            <div @click="closeAction" class="close-button-outer">
                <div class="close-button"></div>
            </div>
        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
//import EmailSignup from '@/components/blocks/EmailSignup'
import QuotesHolder from '@/components/blocks/QuotesHolder'

export default {
    name: "HomePage",
    components: {
        //EmailSignup,
        QuotesHolder
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            fandangoLink:'https://www.fandango.com/huesera-229439/movie-overview',
            quotes: [
                {
                    quote: "Spine-chillingly terrifying",
                    source: "Variety"
                },
                {
                    quote: "A brilliant horror film that tears apart the expectations of domesticity and motherhood",
                    source: "XTRA"
                },
                {
                    quote: "Intelligent, empathetic and spine-chilling",
                    source: "La Estatuilla"
                },
                {
                    quote: "A bold new voice in horror",
                    source: "Dread Central"
                },
                {
                    quote: "A nightmare journey through the dark side of motherhood",
                    source: "Screen Daily"
                },
                {
                    quote: "A singular achievement on the international horror scene",
                    source: "Rue Morgue"
                },
                {
                    quote: "Marks out Garza Cervera as a serious horror talent to watch",
                    source: "Nerdly"
                },
                {
                    quote: "Huesera is driven by a punk feminist heartbeat",
                    source: "AWFJ"
                }
            ]
        }
    },
    mounted() {
        //console.log(this.window.config.previewMode)
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    methods: {
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction() {
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        }
    }
}
</script>